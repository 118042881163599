.carousel {
  display: flex;
  justify-content: center;
}

.carousel .thumbs-wrapper {
  display: none;
}

.section .carousel .carousel-status {
  display: none;
}

.section .carousel .control-arrow {
  display: none;
}

.section .carousel .control-dots {
  display: none;
}